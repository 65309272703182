import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Accordion, Col, Row } from 'react-bootstrap';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';
import Countdown from 'react-countdown';
import { AFKCharacter } from '../../modules/afk/common/components/afk-character';

const AFKIndexPage: React.FC = () => {
  const renderer = ({ days, hours, minutes, completed }) => {
    if (completed) {
      return <span className="time">Event ended</span>;
    } else {
      return (
        <span className="time">
          {days >= 1 ? (
            <>
              {days}d {hours}h
            </>
          ) : (
            <>
              {hours}h {minutes}m
            </>
          )}
        </span>
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderer2 = ({ days, hours, minutes, completed }) => {
    if (completed) {
      return <span className="time">Event started</span>;
    } else {
      return (
        <span className="time">
          {days >= 1 ? (
            <>
              {days}d {hours}h
            </>
          ) : (
            <>
              {hours}h {minutes}m
            </>
          )}
        </span>
      );
    }
  };
  return (
    <DashboardLayout className={'home-page afk-journey'} game="afk">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - AFK Journey wiki and tier list</h1>
        <h2>
          Prydwen.gg is a wiki for AFK Journey. Check our guides, tier lists and
          reviews for characters available in the game.
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      <SectionHeader title={'Event Timeline'} />
      <p>
        The event timeline only shows events that are global - as in they start
        for everyone at the same time. Server specific events like Primal Lords
        won't be added here.
      </p>
      <Row xs={1} xl={2}>
        <Col>
          <div className="tracker-header">
            <h5>Current</h5>
          </div>
          <Accordion className="event-tracker">
            <Accordion.Item className="cele-twins" eventKey="22222">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">
                  New Cele/Hypo (Elijah and Lailah)
                </div>
                <div className="countdown">
                  <span className="time">Event started!</span>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: January 17, 2025, 7:00 (UTC) –
                  permanent
                </p>
                <p className="featured">
                  Featured <strong>S-Level</strong> character:{' '}
                  <AFKCharacter
                    mode="inline"
                    slug="elijah-and-lailah"
                    enablePopover
                  />
                </p>
                <p>
                  Important! New Celestial/Hypogean characters release don't
                  come with their own banner, but rather they are added to the
                  pool of available Cele/Hypo characters you can select in
                  Stargazer.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="ember" eventKey="301">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Emberlight Revelry</div>
                <div className="countdown">
                  <Countdown date={1739750400000} renderer={renderer2} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: January 27, 2025, 0:00 (UTC) –
                  February 16, 2025, 23:59 (UTC)
                </p>
                <p className="featured">
                  The annual Emberlight Festival of Esperia is here! Dive into
                  the new story and events now for generous rewards!
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="astral" eventKey="302">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Astral Fair</div>
                <div className="countdown">
                  <Countdown date={1739750400000} renderer={renderer2} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: February 6, 2025, 0:00 (UTC) –
                  February 16, 2025, 23:59 (UTC)
                </p>
                <p className="featured">
                  Take part in Astral Fair activities to earn Astral Vouchers
                  that can be redeemed for various rewards from the Astral
                  Store.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="banner-valka" eventKey="3">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Character Banner (Valka)</div>
                <div className="countdown">
                  <Countdown date={1740009600000} renderer={renderer} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: January 30, 2025, 0:00 (UTC) –
                  February 19, 2025, 23:59 (UTC)
                </p>
                <p className="featured">
                  Featured <strong>A-Level</strong> character:{' '}
                  <strong>Valka</strong>
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="crystal" eventKey="304">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Crystal Clash</div>
                <div className="countdown">
                  <Countdown date={1740009600000} renderer={renderer2} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: February 10, 2025, 0:00 (UTC) –
                  February 19, 2025, 23:59 (UTC)
                </p>
                <p className="featured">
                  After unlocking Dark Forest 3, you can defend the Crystal
                  Geodes you arrange or attack the Crystal Geodes you come
                  across in Esperia and receive various rewards.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="reverie" eventKey="303">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Reverie Blooms</div>
                <div className="countdown">
                  <Countdown date={1740009600000} renderer={renderer2} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: February 13, 2025, 0:00 (UTC) –
                  February 19, 2025, 23:59 (UTC)
                </p>
                <p className="featured">
                  Complete event quests to earn points and redeem epic rewards!
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="banner-callan" eventKey="4">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Character Banner (Callan)</div>
                <div className="countdown">
                  <Countdown date={1741219200000} renderer={renderer} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: February 13, 2025, 0:00 (UTC) –
                  March 5, 2025, 23:59 (UTC)
                </p>
                <p className="featured">
                  Featured <strong>A-Level</strong> character:{' '}
                  <strong>Callan</strong>
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col>
          <div className="tracker-header">
            <h5>Upcoming</h5>
          </div>
          <Accordion className="event-tracker">
            <Accordion.Item className="season-3" eventKey="300">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Season 3 End</div>
                <div className="countdown">
                  <span className="time">Unknown</span>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: Unknown yet.
                </p>
                <p className="featured">
                  The ending date for Season 3 isn't currently known.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      <SectionHeader title="Active codes" />
      <p>Check all active codes here:</p>
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Promo codes"
          link="/afk-journey/codes"
          image={
            <StaticImage
              src="../../images/afk/categories/category_codes.jpg"
              alt="Promo codes"
            />
          }
        />
      </Row>
      <SectionHeader title="Dream Realm & Primal Lord guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Primal Lord - Mirage Frostspike"
          link="/afk-journey/guides/primal-frostspike"
          image={
            <StaticImage
              src="../../images/afk/categories/category_primalspike.webp"
              alt="Primal Lord - Mirage Frostspike"
            />
          }
          isNew
        />
        <CategoryCard
          title="Dream Realm - Crazed Shellbrute"
          link="/afk-journey/guides/dream-realm-shellbrute"
          image={
            <StaticImage
              src="../../images/afk/categories/category_brute.webp"
              alt="Dream Realm - Crazed Shellbrute"
            />
          }
        />
        <CategoryCard
          title="Dream Realm - Plague Creeper"
          link="/afk-journey/guides/dream-realm-creeper"
          image={
            <StaticImage
              src="../../images/afk/categories/category_creep.webp"
              alt="Dream Realm - Plague Creeper"
            />
          }
        />
        <CategoryCard
          title="Dream Realm - Illucia"
          link="/afk-journey/guides/dream-realm-illucia"
          image={
            <StaticImage
              src="../../images/afk/categories/category_illucia.webp"
              alt="Dream Realm - Illucia"
            />
          }
        />
        <CategoryCard
          title="Dream Realm - Thalassa"
          link="/afk-journey/guides/dream-realm-thalassa"
          image={
            <StaticImage
              src="../../images/afk/categories/category_thal.webp"
              alt="Dream Realm - Thalassa"
            />
          }
        />
        <CategoryCard
          title="Crystal Beetle"
          link="/afk-journey/guides/dream-realm-crystal-beetle"
          image={
            <StaticImage
              src="../../images/afk/categories/category_crystal.webp"
              alt="Crystal Beetle"
            />
          }
        />
        <CategoryCard
          title="Orson"
          link="/afk-journey/guides/dream-realm-orson"
          image={
            <StaticImage
              src="../../images/afk/categories/category_orson.webp"
              alt="Orson"
            />
          }
        />
        <CategoryCard
          title="Lone Gaze"
          link="/afk-journey/guides/dream-realm-lone-gaze"
          image={
            <StaticImage
              src="../../images/afk/categories/category_lone.jpg"
              alt="Primal Lord - Lone Gaze"
            />
          }
        />
        <CategoryCard
          title="Snow Stomper"
          link="/afk-journey/guides/dream-realm-snow-stomper"
          image={
            <StaticImage
              src="../../images/afk/categories/category_yeti.jpg"
              alt="Snow Stomper"
            />
          }
        />
      </Row>
      <SectionHeader title="Guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Frequently Asked Questions"
          link="/afk-journey/guides/frequently-asked-questions"
          image={
            <StaticImage
              src="../../images/afk/categories/category_faq.jpg"
              alt="Frequently Asked Questions"
            />
          }
        />
        <CategoryCard
          title="Reroll guide"
          link="/afk-journey/guides/reroll"
          image={
            <StaticImage
              src="../../images/afk/categories/category_reroll.jpg"
              alt="Reroll guide"
            />
          }
        />
        <CategoryCard
          title="Beginner guide"
          link="/afk-journey/guides/beginner-guide"
          image={
            <StaticImage
              src="../../images/afk/categories/category_beginner.jpg"
              alt="Beginner guide"
            />
          }
        />
        <CategoryCard
          title="Tips and Tricks"
          link="/afk-journey/guides/tips-and-tricks"
          image={
            <StaticImage
              src="../../images/afk/categories/category_tips.jpg"
              alt="Tips and Tricks"
            />
          }
        />
        <CategoryCard
          title="Characters list"
          link="/afk-journey/characters"
          image={
            <StaticImage
              src="../../images/afk/categories/category_characters.jpg"
              alt="Characters list"
            />
          }
        />
        <CategoryCard
          title="Tier List"
          link="/afk-journey/tier-list"
          image={
            <StaticImage
              src="../../images/afk/categories/category_tier.jpg"
              alt="Tier List"
            />
          }
        />
        <CategoryCard
          title="Wishlist tips"
          link="/afk-journey/guides/wishlist-tips"
          image={
            <StaticImage
              src="../../images/afk/categories/category_wishlist.jpg"
              alt="Wishlist tips"
            />
          }
        />
        <CategoryCard
          title="Best AFK Stages Teams"
          link="/afk-journey/guides/afk-progression-teams"
          image={
            <StaticImage
              src="../../images/afk/categories/category_team.jpg"
              alt="Best AFK Stages Teams"
            />
          }
        />
        <CategoryCard
          title="Best Arena Teams"
          link="/afk-journey/guides/arena-teams"
          image={
            <StaticImage
              src="../../images/afk/categories/category_meta.jpg"
              alt="Best Arena Teams"
            />
          }
        />
        <CategoryCard
          title="Best Supreme Arena Teams"
          link="/afk-journey/guides/supreme-arena-teams"
          image={
            <StaticImage
              src="../../images/afk/categories/category_sarena.webp"
              alt="Best Supreme Arena Teams"
            />
          }
        />
        <CategoryCard
          title="Exclusive Weapon priority"
          link="/afk-journey/guides/exclusive-weapon-priority"
          image={
            <StaticImage
              src="../../images/afk/categories/category_ex.jpg"
              alt="Exclusive Weapon priority"
            />
          }
        />
        <CategoryCard
          title="Shop (Emporium) guide"
          link="/afk-journey/guides/shop-guide"
          image={
            <StaticImage
              src="../../images/afk/categories/category_shops.jpg"
              alt="Shop (Emporium) guide"
            />
          }
        />
        <CategoryCard
          title="Spending guide"
          link="/afk-journey/guides/spending-guide"
          image={
            <StaticImage
              src="../../images/afk/categories/category_spending.jpg"
              alt="Spending guide"
            />
          }
        />
        <CategoryCard
          title="Guild - Glyphshade boss"
          link="/afk-journey/guides/guild-glyphshade"
          image={
            <StaticImage
              src="../../images/afk/categories/category_glyph.webp"
              alt="Guild - Glyphshade boss"
            />
          }
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default AFKIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="AFK Journey Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a wiki for AFK Journey. Check our guides, tier lists and reviews for characters available in the game."
    game="afk"
  />
);
